<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Register v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <logo-app class="h-auto w-auto" />
        </b-link>

        <b-card-title class="mb-1">
          Приключение начинается здесь 🚀
        </b-card-title>
        <b-card-text
          v-if="step === 'get-code'"
          class="mb-2"
        >
          Для регистрации введи номер телефона
        </b-card-text>

        <b-card-text
          v-else-if="step === 'check-code'"
          class="mb-2"
        >
          Для подтверждения номера телефона, введи код из СМС
        </b-card-text>

        <b-card-text
          v-else-if="step === 'register'"
          class="mb-2"
        >
          Заполните следующие данные!
        </b-card-text>

        <!-- form -->
        <b-form
          class="auth-register-form mt-2"
          @submit.prevent="submit"
        >
          <small
            v-if="!!error_message"
            class="text-danger d-block mb-1"
          >
            {{ error_message }}
          </small>
          <template v-if="step === 'get-code'">
            <!-- phone -->
            <b-form-group
              label="Телефон"
              label-for="phone"
            >

              <b-form-input
                id="phone"
                v-model.trim="phone"
                v-mask="'+7 (999) 999-99-99'"
                :state="get(validation, 'phone[0]') ? false : null"
                name="register-phone"
                placeholder="+7 (___) ___-__-__"
              />
              <small
                v-if="!!get(validation, 'phone[0]')"
                class="text-danger"
              >
                {{ get(validation, 'phone[0]') }}
              </small>
            </b-form-group>

            <!-- checkbox -->
            <b-form-group>
              <b-form-checkbox
                id="register-privacy-policy"
                v-model="state"
                name="checkbox-1"
                :state="get(validation, 'state[0]') ? false : null"
              >
                Я согласен с
                <b-link>политикой конфиденциальности и условиями</b-link>
                <small
                  v-if="!!get(validation, 'state[0]')"
                  class="text-danger"
                >
                  {{ get(validation, 'state[0]') }}
                </small>
              </b-form-checkbox>
            </b-form-group>
          </template>
          <div v-else-if="step === 'check-code'">
            <!-- code -->
            <b-form-group
              label="Код"
              label-for="code"
            >

              <b-form-input
                id="code"
                v-model.trim="code"
                :state="get(validation, 'code[0]') ? false : null"
                name="register-code"
              />
              <small
                v-if="!!get(validation, 'code[0]')"
                class="text-danger"
              >
                {{ get(validation, 'code[0]') }}
              </small>
            </b-form-group>
          </div>
          <div v-else-if="step === 'register'">
            <!-- surname -->
            <b-form-group
              label="Фамилия *"
              label-for="surname"
            >

              <b-form-input
                id="surname"
                v-model.trim="surname"
                :state="get(validation, 'surname[0]') ? false : null"
                name="surname"
              />
              <small
                v-if="!!get(validation, 'surname[0]')"
                class="text-danger"
              >
                {{ get(validation, 'surname[0]') }}
              </small>
            </b-form-group>

            <!-- name -->
            <b-form-group
              label="Имя *"
              label-for="name"
            >

              <b-form-input
                id="name"
                v-model.trim="name"
                :state="get(validation, 'name[0]') ? false : null"
                name="name"
              />
              <small
                v-if="!!get(validation, 'name[0]')"
                class="text-danger"
              >
                {{ get(validation, 'name[0]') }}
              </small>
            </b-form-group>

            <!-- patronymic -->
            <b-form-group
              label="Отчество *"
              label-for="patronymic"
            >

              <b-form-input
                id="patronymic"
                v-model.trim="patronymic"
                :state="get(validation, 'patronymic[0]') ? false : null"
                name="patronymic"
              />
              <small
                v-if="!!get(validation, 'patronymic[0]')"
                class="text-danger"
              >
                {{ get(validation, 'patronymic[0]') }}
              </small>
            </b-form-group>

            <!-- password -->
            <b-form-group
              label="Пароль *"
              label-for="password"
            >
              <input
                type="text"
                name="login"
                :value="phone"
                style="position: absolute; opacity: 0; pointer-events: none;"
              >
              <b-input-group
                class="input-group-merge"
                :class="{
                  'is-invalid': !!get(validation, 'password[0]')
                }"
              >
                <b-form-input
                  id="password"
                  v-model.trim="password"
                  :type="passwordFieldType"
                  :state="get(validation, 'password[0]') ? false : null"
                  class="form-control-merge"
                  name="register-password"
                  placeholder="Введите пароль"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    :icon="passwordToggleIcon"
                    class="cursor-pointer"
                    @click="togglePasswordVisibility"
                  />
                </b-input-group-append>
              </b-input-group>
              <small
                v-if="!!get(validation, 'password[0]')"
                class="text-danger"
              >
                {{ get(validation, 'password[0]') }}
              </small>
            </b-form-group>

            <!-- password confirmation -->
            <b-form-group
              label="Подтвердите пароль *"
              label-for="password_confirmation"
            >
              <b-input-group
                class="input-group-merge"
                :class="{
                  'is-invalid': !!get(validation, 'password_confirmation[0]')
                }"
              >
                <b-form-input
                  id="password_confirmation"
                  v-model.trim="password_confirmation"
                  :type="passwordConfirmationFieldType"
                  :state="get(validation, 'password_confirmation[0]') ? false : null"
                  class="form-control-merge"
                  name="register-password_confirmation"
                  placeholder="Подтвердите пароль"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    :icon="passwordConfirmationToggleIcon"
                    class="cursor-pointer"
                    @click="passwordConfirmationFieldType = passwordConfirmationFieldType === 'password' ? 'text' : 'password'"
                  />
                </b-input-group-append>
              </b-input-group>
              <small
                v-if="!!get(validation, 'password_confirmation[0]')"
                class="text-danger"
              >
                {{ get(validation, 'password_confirmation[0]') }}
              </small>
            </b-form-group>
          </div>

          <!-- submit button -->
          <b-button
            variant="primary"
            block
            type="submit"
          >
            <template v-if="!sending">
              Отправить
            </template>
            <template v-else>
              <b-row
                align-v="center"
                align-h="center"
                no-gutters
              >
                <b-col cols="auto">
                  <b-spinner
                    small
                    class="mr-1"
                  />
                </b-col>
                <b-col cols="auto">
                  Loading...
                </b-col>
              </b-row>
            </template>
          </b-button>
        </b-form>

        <b-card-text class="text-center mt-2">
          <span>Уже есть аккаунт? </span>
          <b-link :to="{name:'login'}">
            <span>Войти</span>
          </b-link>
        </b-card-text>
      </b-card>
      <!-- /Register v1 -->
    </div>
  </div>

</template>

<script>
import {
  BCard, BLink, BCardTitle, BCardText, BForm,
  BButton, BFormInput, BFormGroup, BInputGroup, BInputGroupAppend, BFormCheckbox,
  BSpinner, BRow, BCol,
} from 'bootstrap-vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { get, has } from 'lodash'
import { getCode, checkCode, register } from '@/services/main-api/registration'
import LogoApp from '@/components/LogoApp.vue'

export default {
  components: {
    // BSV
    BCard,
    BLink,
    BCardTitle,
    BCardText,
    BForm,
    BButton,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    BSpinner,
    BRow,
    BCol,
    LogoApp,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      sending: false,
      state: false,
      step: 'get-code',
      phone: '',
      session: '',
      code: '',
      name: '',
      password: '',
      password_confirmation: '',
      patronymic: '',
      surname: '',
      locale: 2,
      validation: {},
      error_message: '',
      passwordConfirmationFieldType: 'password',
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordConfirmationToggleIcon() {
      return this.passwordConfirmationFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    async submit() {
      if (this.step === 'get-code') {
        await this.getCodeStep()
      } else if (this.step === 'check-code') {
        await this.checkCodeStep()
      } else if (this.step === 'register') {
        await this.registerStep()
      }
    },
    async getCodeStep() {
      this.clearValidation()
      const phone = this.phone.replace(/[^+\d]/g, '')
      let error = false

      if (!this.state) {
        error = true
        this.validation.state = ['Необходимо согласится с политикой конфиденциальности и условиями']
      }
      if (!phone) {
        error = true
        this.validation.phone = ['Укажите ваш телефон']
      }

      if (error) {
        return
      }

      this.sending = true

      try {
        const res = await getCode({
          locale: this.locale,
          phone,
        })

        this.session = res.data.session

        if (res.data.code) {
          this.code = res.data.code
        }

        this.step = 'check-code'
      } catch (e) {
        this.catchValidation(e)
      } finally {
        this.sending = false
      }
    },
    async checkCodeStep() {
      this.clearValidation()

      let error = false

      if (!this.code) {
        error = true
        this.validation.code = ['Укажите код из смс']
      }
      if (error) {
        return
      }

      this.sending = true

      try {
        await checkCode({
          code: this.code,
          session: this.session,
        })

        this.step = 'register'
      } catch (e) {
        this.catchValidation(e)
      } finally {
        this.sending = false
      }
    },
    async registerStep() {
      this.clearValidation()
      const phone = this.phone.replace(/[^+\d]/g, '')

      let hasError = false

      if (!this.name) {
        this.validation.name = ['Укажите ваше имя']
        hasError = true
      }

      if (!this.password) {
        this.validation.password = ['Введите пароль']
        hasError = true
      }

      if (!this.password_confirmation) {
        this.validation.password_confirmation = ['Подтвердите пароль']
        hasError = true
      }

      if (!this.patronymic) {
        this.validation.patronymic = ['Укажите ваше отчество']
        hasError = true
      }

      if (!this.surname) {
        this.validation.surname = ['Укажите вашу фамилию']
        hasError = true
      }

      if (this.password && this.password_confirmation && this.password !== this.password_confirmation) {
        this.validation.password = ['Пароль не совпадает']
        this.validation.password_confirmation = ['Пароль не совпадает']
        hasError = true
      }
      if (hasError) {
        return
      }

      this.sending = true

      try {
        await register({
          session: this.session,
          name: this.name,
          password: this.password,
          patronymic: this.patronymic,
          surname: this.surname,
        })

        await this.$store.dispatch('auth/login', {
          login: phone,
          password: this.password,
        })
        this.clearForm()
        await this.$router.push({ name: 'dashboard' })
      } catch (e) {
        this.catchValidation(e)
      } finally {
        this.sending = false
      }
    },
    catchValidation(e) {
      if (has(e.response, 'data.errors') || has(e.response, 'data.error')) {
        this.validation = get(e.response, 'data.errors')
        this.error_message = get(e.response, 'data.error', '')
      } else {
        const error = get(e.response, 'data')
        if (typeof error === 'string') {
          this.error_message = error
        } else {
          this.validation = error
        }
      }
    },
    clearValidation() {
      this.validation = {}
      this.error_message = ''
    },
    get,
    clearForm() {
      this.sending = false
      this.state = false
      this.step = 'get-code'
      this.phone = ''
      this.session = ''
      this.code = ''
      this.name = ''
      this.password = ''
      this.password_confirmation = ''
      this.patronymic = ''
      this.surname = ''
      this.validation = {}
      this.error_message = ''
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
