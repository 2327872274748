import axiosIns from '@/libs/axios'

export function getCode({ phone, locale }) {
  return axiosIns.post('/registration/get-code', {
    phone,
    locale,
  })
}

export function checkCode({ code, session }) {
  return axiosIns.post('/registration/check-code', {
    code,
    session,
  })
}

export function register({
  session, name, patronymic, surname, password,
}) {
  return axiosIns.post('/registration/register', {
    session,
    name,
    patronymic,
    surname,
    password,
  })
}

export default {
  getCode,
  checkCode,
  register,
}
